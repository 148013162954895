<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom card-transparent p-0">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-4"
            id="kt_wizard_v4"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Nav-->
            <div class="wizard-nav">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Api</div>
                      <div class="wizard-desc">Thêm một sản phẩm</div>
                    </div>
                  </div>
                </div>
                <div class="wizard-step" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Excel</div>
                      <div class="wizard-desc">Thêm nhiều sản phẩm</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Nav -->

            <!--begin: Wizard Body-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-12">
                    <!--begin: Wizard Form-->

                    <!--begin: Wizard Step 1-->
                    <div
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <div class="col-12 row" style="padding-left: 0px">
                        <b-form-group class="col-12">
                          <b>Tìm Kiếm :</b>
                          <span class="text-primary"
                            >(Barcode, code, productName)</span
                          >
                          <b-input-group label="Id nhanh:">
                            <b-form-input
                              type="text"
                              required
                              v-model="productNhanh"
                              size="sm"
                              class="col-4"
                              placeholder="Nhập Barcode, code , productName"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                variant="primary"
                                size="sm"
                                @click="getProductNhanh()"
                              >
                                <i class="fa fa-search" aria-hidden="true"></i>
                                Tìm trên Nhanh
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger"
                            >(Yêu cầu nhập đúng mã sản phẩm)</small
                          >
                          <div v-html="findProductNhanh"></div>
                        </b-form-group>
                      </div>
                      <span class="font-weight-bolder mb-4"
                        >Danh sách sản phẩm:</span
                      >
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fields"
                        :items="listProductStock"
                        v-if="renderComponent"
                      >
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-warning text-center"
                            @click="getProductVariant(row.item)"
                            v-b-tooltip
                            title="Lấy sản phẩm cha con"
                            >mdi-settings</v-icon
                          >
                          <v-icon
                            small
                            class="text-danger text-center"
                            @click="deleteItemInArray(row.item.id, null)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                      <div class="mb-5 mt-5">
                        <button
                          v-show="listProductStock.length > 0"
                          v-bind:style="btnCreate"
                          type="button"
                          class="btn btn-primary font-weight-bolder btn-sm button"
                          @click="createProduct()"
                        >
                          Cập nhật
                        </button>
                      </div>
                    </div>
                    <!--end: Wizard Step 1-->

                    <!--begin: Wizard Step 2-->
                    <div class="pb-5" data-wizard-type="step-content">
                      <div>
                        <!-- Styled -->
                        <b-form-file
                          v-model="fileExcel"
                          :state="Boolean(fileExcel)"
                          placeholder="Chọn file hoặc thả file..."
                          drop-placeholder="Drop file here..."
                          accept=".xlsx, .xls, .csv"
                          v-on:change="getFile()"
                          ref="file"
                          class="mb-3 col-5"
                        >
                        </b-form-file>
                        <div v-show="fileExcel">
                          File được chọn:
                          <b>{{ fileExcel ? fileExcel.name : '' }}</b>
                        </div>
                        <div class="mb-3">
                          <b-button
                            variant="warning"
                            size="sm"
                            @click="uploadExcel()"
                            v-show="fileExcel"
                          >
                            Upload
                          </b-button>

                          <b-button
                            class="ml-1"
                            variant="success"
                            size="sm"
                            @click="getProductsFromNhanh()"
                            v-show="listProductExcel.length > 0"
                          >
                            Lấy api nhanh
                          </b-button>
                        </div>

                        <!-- Plain mode -->
                      </div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fieldExcels"
                        :items="listProductExcel"
                        v-if="renderComponent"
                      >
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-warning text-center"
                            @click="getProductVariant(row.item)"
                            v-b-tooltip
                            title="Lấy sản phẩm cha con"
                            >mdi-settings</v-icon
                          >
                          <v-icon
                            small
                            class="text-danger text-center"
                            @click="
                              deleteItemInArray(row.item.barCode, 'excel')
                            "
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                      <div class="mb-5 mt-5">
                        <button
                          v-show="listProductExcel.length > 0"
                          v-bind:style="btnCreate"
                          type="button"
                          class="btn btn-primary font-weight-bolder btn-sm button"
                          @click="createProduct('excel')"
                        >
                          Cập nhật
                        </button>
                      </div>
                    </div>
                    <!--end: Wizard Step 2-->

                    <!--begin: Wizard Step 3-->

                    <!--end: Wizard Step 3-->

                    <!--begin: Wizard Step 4-->

                    <!--end: Wizard Step 4-->

                    <!--begin: Wizard Actions -->
                    <!--end: Wizard Actions -->

                    <!--end: Wizard Form-->
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Bpdy-->
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

import { validationMixin } from 'vuelidate';

import axios from 'axios';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { cmdUrl } from '../../../utils/apiUrl';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
export default {
  mixins: [validationMixin],
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
      },
      fields: [
        {
          key: 'id',
          label: 'Id Nhanh',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
        },
        {
          key: 'barCode',
          label: 'Mã barcode',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'categoryName',
          label: 'Tên danh mục',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'parentProductId',
          label: 'SP Cha/Con',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
          formatter: (value) => {
            return value === null ? 'SP cha' : 'SP con';
          },
        },
        {
          key: 'totalQuantityInStock',
          label: 'Tồn kho tổng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
        },
        {
          key: 'productType',
          label: 'Loại sản phẩm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
          formatter: (value) => {
            return value === 1
              ? 'Sản phẩm thường'
              : value === 2
              ? 'Sản phẩm Imei'
              : value === 3
              ? 'Sản phẩm combo'
              : value === 4
              ? 'Sản phẩm dịch vụ'
              : 'Không có type';
          },
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'avgCost',
          label: 'Giá trung bình',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          formatter: (value) => {
            return this.formatMoney(value);
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
        },
      ],
      fileExcel: null,
      fieldExcels: [],
      listProductExcel: [],
      listProductStock: [],
      searchStore: '',
      inventorySelected: null,
      inputSearchProductProp: {
        id: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      renderComponent: true,
      productNhanh: null,
      findProductNhanh: '',
    };
  },
  created() {
    this.fieldExcels = [...this.fields];
    let productNhanh = this.$router.history.current.query.productNhanh;
    if (productNhanh) {
      this.productNhanh = productNhanh;
      this.getProductNhanh();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Đồng bộ sản phẩm' }]);
    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on('beforeNext', function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },

    getProductVariant(item) {
      this.findProductNhanh =
        '<p><strong class="text-primary">Loading...</strong><span role="status" class="spinner-grow spinner-grow-sm text-primary"><span class="sr-only">Small Spinner</span></span></p>';
      let id = item.parentProductId ? item.parentProductId : item.id;

      let url = cmdUrl.ToolSync.productSearch;
      axios
        .get(url, {
          params: {
            parentId: id,
          },
        })
        .then((rs) => {
          let products = rs.data.data;
          let productExists = [];
          let productNotExists = [];
          this.findProductNhanh = '';
          products.forEach((x) => {
            let exist = this.listProductStock.find((y) => y.id === x.id);
            if (exist) {
              productExists.push(x);
            } else {
              productNotExists.push(x);
            }
          });
          if (productNotExists.length > 0) {
            this.listProductStock = this.listProductStock.concat(
              productNotExists
            );
            this.makeToastSuccess(`Đã thêm sản phẩm`);
          }
          if (productExists.length > 0) {
            this.makeToastSuccess(`Đã thêm sản phẩm`);
            this.findProductNhanh = '';
          }
        })
        .catch((err) => {
          this.makeToastFaile(err.message);
        });
    },
    deleteItemInArray(productId, isExcel) {
      let i = 0;
      for (; i < isExcel ? this.listProductExcel : this.listProductStock; i++) {
        if (isExcel) {
          if (this.listProductExcel[i].barCode === productId) {
            this.listProductExcel.splice(i, 1);
            this.makeToastSuccess(`Đã xoá ${productId} ra khỏi danh sách`);
          }
        } else {
          if (this.listProductStock[i].id === productId) {
            this.listProductStock.splice(i, 1);
            this.makeToastSuccess(`Đã xoá ${productId} ra khỏi danh sách`);
          }
        }
      }
    },
    getProductNhanh() {
      this.$router
        .replace({
          query: { productNhanh: this.productNhanh },
        })
        .catch(() => {});
      this.findProductNhanh =
        '<p><strong class="text-primary">Loading...</strong><span role="status" class="spinner-grow spinner-grow-sm text-primary"><span class="sr-only">Small Spinner</span></span></p>';

      let url = cmdUrl.ToolSync.productSearch;
      axios
        .get(url, {
          params: {
            name: this.productNhanh,
          },
        })
        .then((rs) => {
          let products = rs.data.data;
          let productExists = [];
          let productNotExists = [];
          this.findProductNhanh = '';
          products.forEach((x) => {
            let exist = this.listProductStock.find((y) => y.id === x.id);
            if (exist) {
              productExists.push(x);
            } else {
              productNotExists.push(x);
            }
          });
          if (productNotExists.length > 0) {
            this.listProductStock = this.listProductStock.concat(
              productNotExists
            );
            this.makeToastSuccess(`Đã thêm sản phẩm`);
          }
          if (productExists.length > 0) {
            this.makeToastSuccess(
              `${productExists
                .map((x) => x.id)
                .join(',\n')} sản phẩm đã tồn tại`
            );
            this.findProductNhanh = '';
          }
        })
        .catch((err) => {
          this.makeToastFaile(err.message);
          this.findProductNhanh = '';
        });
    },
    getProductsFromNhanh() {
      let products = this.listProductExcel;
      if (products.length > 0) {
        let list = [];
        for (const item of products) {
          let url = cmdUrl.ToolSync.productSearch;
          axios
            .get(url, {
              params: {
                name: item.barCode,
              },
            })
            .then((rs) => {
              if (rs.data.status) {
                list.push(rs.data.data[0]);
              } else {
                this.makeToastFaile(`Sản phẩm ${item.productName} lỗi`);
              }
            })
            .catch((err) => {
              this.makeToastFaile(err.message);
            });
        }

        this.listProductExcel = list;
      } else {
        this.makeToastFaile(`Danh sách sản phẩm trống`);
      }
    },
    createProduct: async function (isExcel) {
      let data = {
        listProduct: isExcel ? this.listProductExcel : this.listProductStock,
      };
      if (data.listProduct.length > 0) {
        //console.log(`Data: ${JSON.stringify(data)}`);
        let url = cmdUrl.ToolSync.product;
        axios
          .post(url, data)
          .then(({ data }) => {
            if (data.status) {
              this.makeToastSuccess(data.message);

              // setTimeout(() => {
              //   this.$router.push({
              //     name: 'list-inventory-balance'
              //   });
              // }, 1000);
            } else {
              this.makeToastFaile(data.message);
              this.isNew = true;
            }
          })
          .catch(({ response }) => {
            this.makeToastFaile(response.$error);
          });
      } else {
        this.makeToastFaile('Vui lòng chọn sản phẩm!');
        this.isNew = true;
      }
    },
    uploadExcel() {
      let formData = new FormData();
      formData.append('file', this.fileExcel);
      let url = cmdUrl.ToolSync.productExcel;
      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((rs) => {
          if (rs.data.status) {
            this.listProductExcel = rs.data.data;
            this.makeToastSuccess('Đọc file thành công');
          } else {
            this.makeToastFaile('Đọc file thất bại');
          }
        })
        .catch(() => {
          this.makeToastFaile('Đọc file thất bại');
        });
    },
    getFile() {
      this.fileExcel = this.$refs.file.files[0];
    },
  },
};
</script>
<style scoped>
.title-center {
  text-align: center;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.input-group-append {
  cursor: pointer;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
